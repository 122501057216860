import { useEffect, useMemo, useState } from 'react';
import { InitiativeQuestionManagementResponse, ViewType, useGetInitiativeQuestionsQuery } from '@api/admin-dashboard';
import LoadingPlaceholder from '@components/LoaderContainer/LoadingPlaceholder';
import { CheckboxState } from '@components/common/Checkbox';
import { ActionsToolbar } from '@components/survey-question-list/partials/ActionsToolbar';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { BulkActionProps } from '@components/survey-question-list/survey-question-list';
import './QuestionManagement.scss';
import { getNumericSelectedQuestions } from '@components/survey-question-list/utils';
import { QuestionFilter, QuestionFilters, UnitOption } from './QuestionFilter';
import { QuestionTable } from './QuestionTable';
import { useAppSelector } from '@reducers/index';
import { getRootOrg, isRootOrg } from '@selectors/initiative';
import { BasicAlert } from '@components/alert/BasicAlert';
import { useGetCustomTagsQuery } from '@api/metric-groups';
import Loader from '@components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { QUESTION } from '@constants/terminology';
import { checkCanAccessRootOrg } from '@selectors/user';
import { useSearchDebounceWithFlexMap } from '@hooks/useSearchDebounceWithFlexMap';
import { getFilteredUtrIdsByTag, isMatchSelectedPacks, isMatchSelectedTags, isMatchUnitOption } from '../util';
import { QuestionConfigurationButton, Tabs } from '@features/question-configuration';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import QuestionManagementContainer from './QuestionManagementContainer';

export enum ShowAs {
  Page = 'page',
  Section = 'section',
}

export const NO_QUESTIONS_MESSAGE = {
  FILTER: `No ${QUESTION.PLURAL} match your criteria`,
  IN_SCOPE: `No ${QUESTION.PLURAL} are used in scope`,
};

const initialState = {
  packs: [],
  tags: [],
  unit: UnitOption.DefaultAndOverride,
};

interface QuestionManagementProps {
  pageSize?: number;
  showAs?: ShowAs;
  initiativeId: string;
}

export const QuestionManagement = ({ pageSize, showAs = ShowAs.Section, initiativeId }: QuestionManagementProps) => {
  const isRootOrganization = useAppSelector(isRootOrg);
  const rootOrg = useAppSelector(getRootOrg);
  const canAccessRootOrg = useAppSelector(checkCanAccessRootOrg);
  const [viewType, setViewType] = useState<ViewType>(ViewType.InScope);
  const [filters, setFilters] = useState<QuestionFilters>(initialState);
  const [selectedQuestions, setSelectedQuestions] = useState<BulkActionUtr[]>([]);

  const {
    data = {} as InitiativeQuestionManagementResponse,
    isFetching,
    isLoading,
    isSuccess,
    refetch,
    error: loadUtrError,
  } = useGetInitiativeQuestionsQuery({ initiativeId, viewType }, { skip: !initiativeId });

  const { data: tags = [], error: loadTagError } = useGetCustomTagsQuery(rootOrg?._id ?? skipToken, { skip: !canAccessRootOrg});

   const rootInitiativeUtrMap = useMemo(
     () =>
       new Map<string, InitiativeUniversalTracker>(
         data.rootInitiativeUtrs?.map((utr: InitiativeUniversalTracker) => [utr.universalTrackerId, utr])
       ),
     [data.rootInitiativeUtrs]
   );

  const utrIds = useMemo(() => {
    return (data.utrs || []).map((utr) => utr._id);
  }, [data.utrs]);

  const { onSearch, matchedQuestions } = useSearchDebounceWithFlexMap(data.utrs);

  const isFiltering =
    filters.packs.length > 0 || filters.tags.length > 0 || filters.unit !== UnitOption.DefaultAndOverride;

  const filteredQuestions = useMemo(() => {
    if (isFiltering) {
      const filteredUtrIdsByTag = getFilteredUtrIdsByTag({ utrIds, tags, values: filters.tags });
      return matchedQuestions.filter(
        (utr) =>
          isMatchSelectedTags({ utrId: utr._id, selectedTags: filters.tags, filteredUtrIdsByTag }) &&
          isMatchSelectedPacks(utr, filters.packs) &&
          isMatchUnitOption({ unitOption: filters.unit, utr, rootInitiativeUtrMap })
      );
    }

    return matchedQuestions;
  }, [isFiltering, filters, matchedQuestions, utrIds, tags, rootInitiativeUtrMap]);

  useEffect(() => {
    if (selectedQuestions.length === 0) {
      return;
    }
    const filteredUtrIds = filteredQuestions.map((question) => question._id);
    setSelectedQuestions((prev) => prev.filter((question) => filteredUtrIds.includes(question._id)));
  }, [filteredQuestions, selectedQuestions.length]);

  if (!rootOrg) {
    return <Loader />;
  }

  const numericSelectedQuestions = getNumericSelectedQuestions(selectedQuestions);

  const isSelectAll = selectedQuestions.length > 0 && selectedQuestions.length === filteredQuestions?.length;

  const handleUnselectAll = () => setSelectedQuestions([]);

  const toggleSelectAll = () => (isSelectAll ? handleUnselectAll() : setSelectedQuestions(filteredQuestions));

  const toggleSelectQuestion = (utr: BulkActionUtr, status: CheckboxState) => {
    const newSelectedQuestions =
      status === CheckboxState.Checked
        ? selectedQuestions.filter((q) => q._id !== utr._id)
        : [...selectedQuestions, utr];

    return setSelectedQuestions(newSelectedQuestions);
  };

  const handleQuestionFilter = ({ name, value }: { name: string; value: string[] | UnitOption | boolean }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleReload = async ({ reloadSurvey = false }: { reloadSurvey?: boolean } = {}) => {
    if (reloadSurvey) {
      await refetch();
    }
  };

  const modalProps = {
    selectedQuestions,
    numericSelectedQuestions,
    initiativeId,
    handleReload,
  };

  const renderActionToolbar = (
    props: Omit<BulkActionProps, 'surveyId' | 'triggerBulkAction' | 'selectedQuestions'> & {
      selectedQuestions: BulkActionUtr[];
    }
  ) => {
    const listButtons = isRootOrganization
      ? [
          {
            name: 'metric-overrides',
            Component: QuestionConfigurationButton,
            buttonProps: {
              name: Tabs.MetricOverrides,
              modalProps,
            },
          },
          {
            name: 'input-overrides',
            Component: QuestionConfigurationButton,
            buttonProps: {
              name: Tabs.InputOverrides,
              disabled: !numericSelectedQuestions.length,
              modalProps,
            },
          },
          {
            name: 'tags',
            Component: QuestionConfigurationButton,
            buttonProps: {
              name: Tabs.Tags,
              modalProps,
            },
          },
        ]
      : [];
    return <ActionsToolbar {...props} buttons={listButtons} />;
  };

  const renderNoQuestionsAlert = () => {
    if (isFiltering) {
      return <BasicAlert type='secondary'>{NO_QUESTIONS_MESSAGE.FILTER}</BasicAlert>;
    }
    if (viewType === ViewType.InScope) {
      return <BasicAlert type='secondary'>{NO_QUESTIONS_MESSAGE.IN_SCOPE}</BasicAlert>;
    }
    return null;
  };

  return (
    <QuestionManagementContainer>
      <QuestionFilter
        viewType={viewType}
        toggleViewType={(viewType: ViewType) => setViewType(viewType)}
        showFilters={showAs === ShowAs.Page}
        filters={filters}
        handleQuestionFilter={handleQuestionFilter}
        onSearch={onSearch}
        rootInitiativeId={rootOrg._id}
        canAccessRootOrg={canAccessRootOrg}
      />
      {[loadUtrError, loadTagError].map((error, idx) =>
        error ? (
          <BasicAlert key={idx} type='danger'>
            {error.message}
          </BasicAlert>
        ) : null
      )}
      <LoadingPlaceholder height={200} isLoading={selectedQuestions.length === 1 ? isFetching : isLoading}>
        {isSuccess && filteredQuestions.length > 0 ? (
          <QuestionTable
            data={filteredQuestions}
            utrTagMap={data.utrTagMap}
            rootInitiativeUtrMap={rootInitiativeUtrMap}
            toggleSelectAll={toggleSelectAll}
            selectedQuestions={selectedQuestions}
            toggleSelectQuestion={toggleSelectQuestion}
            isSelectAll={isSelectAll}
            pageSize={pageSize}
            isRootOrganization={isRootOrganization}
            hiddenOptions={showAs === ShowAs.Page ? [] : [Tabs.MetricOverrides, Tabs.InputOverrides]}
            modalProps={modalProps}
          />
        ) : (
          renderNoQuestionsAlert()
        )}
        <div className='question--list__sticky-toolbar'>
          {renderActionToolbar({
            initiativeId,
            questionCount: filteredQuestions.length,
            selectedQuestions,
            toggleSelectAll,
            handleClose: handleUnselectAll,
          })}
        </div>
      </LoadingPlaceholder>
    </QuestionManagementContainer>
  );
};
