import React, { useContext, useState } from 'react';
import { QuestionManagementContext } from './QuestionManagementContainer';
import Table, { TableColumn } from '@components/table/Table';
import { Button } from 'reactstrap';
import { TagItem } from '@components/survey/question/tags/TagItem';
import { getInitialState } from '@components/utr-decimal/utils';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { Checkbox, CheckboxState } from '@components/common/Checkbox';
import { InitiativeQuestionManagementResponse } from '@api/admin-dashboard';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { UniversalTrackerBlueprintMin, UtrValueType } from '@g17eco/types/universalTracker';
import { QUESTION } from '@constants/terminology';
import { getNumericSelectedQuestions } from '@components/survey-question-list/utils';
import { Tabs, QuestionConfigurationModal, QuestionConfigurationModalProps, InputOverrideFormType } from '@features/question-configuration';
import { PopoverButton } from '@g17eco/molecules';
import IconButton from '@components/button/IconButton';
import { GLOBAL_UPDATE_CODE, NON_NUMERIC_SELECTED_QUESTION_TOOLTIP } from '@features/question-configuration/utils';

interface QuestionTableProps {
  data: InitiativeQuestionManagementResponse['utrs'];
  utrTagMap: InitiativeQuestionManagementResponse['utrTagMap'];
  rootInitiativeUtrMap: Map<string, InitiativeUniversalTracker>;
  toggleSelectAll: () => void;
  selectedQuestions: BulkActionUtr[];
  toggleSelectQuestion: (utr: BulkActionUtr, status: CheckboxState) => void;
  isSelectAll: boolean;
  isRootOrganization: boolean;
  pageSize?: number;
  hiddenOptions: Tabs[];
  modalProps: QuestionConfigurationModalProps;
}

export const QuestionTable = (props: QuestionTableProps) => {
  const {
    data,
    utrTagMap,
    rootInitiativeUtrMap,
    selectedQuestions,
    toggleSelectAll,
    toggleSelectQuestion,
    isSelectAll = false,
    isRootOrganization = false,
    pageSize,
    hiddenOptions,
  } = props;

  const isQuestionSelected = (utrId: string) => selectedQuestions.some((utr) => utr._id === utrId);

  const [metricToOverride, setMetricToOverride] = useState<BulkActionUtr | undefined>(undefined);
  const [tab, setTab] = useState<Tabs | undefined>();
  const { setInputOverrideType, setUtrvConfigCode } = useContext(QuestionManagementContext);

  const setActiveTab = (tab: Tabs | undefined) => {
    if (tab === undefined) {
      setMetricToOverride(undefined);
      setUtrvConfigCode(undefined);
    }
    setTab(tab);
  };

  const onClickMetricToOverride = (question: UniversalTrackerBlueprintMin) => {
    setMetricToOverride(question);
    setActiveTab(Tabs.MetricOverrides);
    setInputOverrideType(undefined);
    setUtrvConfigCode(undefined);
  };

  const columns: TableColumn<UniversalTrackerBlueprintMin>[] = [
    {
      Header: QUESTION.CAPITALIZED_SINGULAR,
      accessor: 'name',
    },
    {
      id: 'tags',
      Header: 'Tags',
      Cell: (c) => {
        const tags = utrTagMap[c.row.original._id];
        return tags && tags.length
          ? tags.map((tag: string) => {
              return <TagItem key={tag} groupName={tag} />;
            })
          : '-';
      },
      disableSortBy: true,
    },
    ...(hiddenOptions.includes(Tabs.InputOverrides)
      ? []
      : [
          {
            Header: 'Unit type',
            Cell: (c: any) => {
              return getDisplayedUnit(c.row.original, rootInitiativeUtrMap);
            },
          },
        ]),
    {
      id: 'decimals',
      Header: 'Decimals',
      cellProps: { style: { width: 20 } },
      accessor: (c) => {
        const rootInitiativeUtr = rootInitiativeUtrMap.get(c._id);
        if (!rootInitiativeUtr) {
          return '-';
        }

        const defaultDecimal = getInitialState({
          inputType: InputOverrideFormType.SingleInput,
          tableColumns: [],
          valueValidation: rootInitiativeUtr.valueValidation,
        });

        return <div>{defaultDecimal[GLOBAL_UPDATE_CODE].value ?? '-'}</div>;
      },
    },
  ];

  if (isRootOrganization) {
    columns.push({
      id: 'action',
      cellProps: { style: { width: 20 }, className: 'text-center' },
      Header: () => (
        <Button color='link' onClick={toggleSelectAll}>
          {isSelectAll ? 'Unselect all' : 'Select all'}
        </Button>
      ),
      Cell: (c) => {
        const question = c.row.original;
        const status = isQuestionSelected(question._id) ? CheckboxState.Checked : CheckboxState.Unchecked;
        return (
          <div className='d-flex align-items-center gap-2'>
            {hiddenOptions.includes(Tabs.InputOverrides) ? null : (
              <IconButton
                color='transparent'
                outline={false}
                icon={'fal fa-pencil text-ThemeIconPrimary'}
                onClick={() => (onClickMetricToOverride(question))}
              />
            )}
            <Checkbox
              status={isQuestionSelected(c.row.original._id) ? CheckboxState.Checked : CheckboxState.Unchecked}
              onChange={() => toggleSelectQuestion(c.row.original, status)}
            />
          </div>
        );
      },
      disableSortBy: true,
    });
  }

  const renderQuestionConfigurationModal = () => {
    if (!metricToOverride) {
      return null;
    }
    const selectedQuestions = [metricToOverride];
    const numericSelectedQuestions = getNumericSelectedQuestions(selectedQuestions);

    return (
      <QuestionConfigurationModal
        {...props.modalProps}
        selectedQuestions={selectedQuestions}
        numericSelectedQuestions={numericSelectedQuestions}
        tabs={[
          { code: Tabs.MetricOverrides },
          {
            code: Tabs.InputOverrides,
            disabled: numericSelectedQuestions.length === 0,
            tooltip: numericSelectedQuestions.length === 0 ? NON_NUMERIC_SELECTED_QUESTION_TOOLTIP : '',
          },
          { code: Tabs.Tags },
        ]}
        activeTab={tab}
        setActiveTab={setActiveTab}
      />
    );
  };

  return (
    <>
      <Table data={data} columns={columns} pageSize={pageSize ?? data.length} />
      {renderQuestionConfigurationModal()}
    </>
  );
};

const getUnitText = ({
  unit,
  numberScale,
}: {
  unit: string | undefined;
  numberScale: string | undefined;
}) => {
  if(!unit) {
    return numberScale ?? '-';
  }
  return `${numberScale ? `${numberScale} ` : ''}${unit ?? '-'}`;
};

const getDisplayedUnit = (utr: BulkActionUtr, rootInitiativeUtrMap: Map<string, InitiativeUniversalTracker>) => {
  switch (utr.valueType) {
    case UtrValueType.Percentage:
    case UtrValueType.NumericValueList:
    case UtrValueType.Number: {
      const initiativeUtr = rootInitiativeUtrMap.get(utr._id);
      const unit = initiativeUtr?.unitInput ?? utr.unit;
      const numberScale = initiativeUtr?.numberScaleInput ?? utr.numberScale;
      return <span>{getUnitText({ unit, numberScale })}</span>;
    }
    case UtrValueType.Table: {
      const initiativeUtr = rootInitiativeUtrMap.get(utr._id);
      return (
        <PopoverButton id={utr._id} title='Unit type'>
          {utr.valueValidation?.table?.columns.map((col) => {
            const initiativeUtrColumn = initiativeUtr?.valueValidation?.table?.columns.find((c) => c.code === col.code);
            const unit = initiativeUtrColumn?.unitInput ?? col.unit;
            const numberScale = initiativeUtrColumn?.numberScaleInput ?? col.numberScale;

            return (
              <div key={col.code} className='d-flex align-items-center justify-content-between gap-3 py-1'>
                <div>{col.name}</div>
                <div>{getUnitText({ unit, numberScale })}</div>
              </div>
            );
          })}
        </PopoverButton>
      );
    }
    default:
      return '-';
  }
};
