import { OrganizationUserRole } from '@constants/user';
import { AssuranceListPortfolio, Organization } from '@g17eco/types/assurance';
import { CurrentUserData } from '@reducers/current-user';

export enum AssuranceAction {
  CanManage = 'can-manage', // Including managing users, delegating metrics, and assuring portfolio
  CanViewPortfolio = 'view-portfolio',
  CanAssureMetrics = 'assure-metrics',
}

const organizationPermissionCheckMap = {
  [AssuranceAction.CanManage]: [OrganizationUserRole.Admin],
  [AssuranceAction.CanViewPortfolio]: [
    OrganizationUserRole.Admin,
    OrganizationUserRole.Assurer,
    OrganizationUserRole.Viewer,
  ],
  [AssuranceAction.CanAssureMetrics]: [OrganizationUserRole.Admin, OrganizationUserRole.Assurer],
};

export const hasOrganizationPermission = ({
  user,
  action,
  organization: { permissions },
}: {
  user: CurrentUserData;
  action: AssuranceAction;
  organization: Pick<Organization, 'permissions'>;
}) => {
  if (!permissions || permissions.length === 0) {
    return false;
  }
  const requiredPermissions = organizationPermissionCheckMap[action];
  return permissions.some(
    (p) => p.userId === user._id && requiredPermissions.some((role) => p.permissions.includes(role))
  );
};

export const hasPortfolioPermission = ({
  user,
  action,
  assurancePortfolio: { assurers },
}: {
  user: CurrentUserData;
  action: AssuranceAction;
  assurancePortfolio: Pick<AssuranceListPortfolio, 'assurers'>;
}) => {
  if (assurers.length === 0) {
    return false;
  }
  switch (action) {
    case AssuranceAction.CanManage:
      return assurers.some((assurer) => assurer._id === user._id && assurer.isAdmin);
    case AssuranceAction.CanViewPortfolio:
    case AssuranceAction.CanAssureMetrics:
      return assurers.some((assurer) => assurer._id === user._id && (assurer.isAdmin || assurer.isAssurer));
    default:
      return false;
  }
};

export const hasAssurancePermission = ({
  user,
  action,
  organization,
  assurancePortfolio,
}: {
  user?: CurrentUserData;
  action: AssuranceAction;
  organization?: Pick<Organization, 'permissions'>;
  assurancePortfolio?: Pick<AssuranceListPortfolio, 'assurers'>;
}) => {
  if (!user || !organization || !assurancePortfolio) {
    return false;
  }

  return (
    hasOrganizationPermission({ user, action, organization }) ||
    hasPortfolioPermission({ user, action, assurancePortfolio })
  );
};
