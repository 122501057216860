import { useContext } from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Action } from '../../../constants/action';
import { AssuranceRoles } from '../../../constants/assurance';
import { UpdatePermissionData } from '../../../types/assurance';
import { isValidEmail } from '../../../utils';
import { Suggestion } from '../../search/SearchComponent';
import UserSearch from '../../search/UserSearch';
import { ACTION, TeamMemberContext } from './TeamMemberContainer';
import { BasicAlert } from '../../alert/BasicAlert';

interface AddMemberPopupType {
  isOpen: boolean;
  toggleModal: () => void;
  role: AssuranceRoles;
}

export const AddMemberPopup = (props: AddMemberPopupType) => {
  const { isOpen, toggleModal, role } = props;
  const { portfolioData, submitData, updatePermissions, dispatch } = useContext(TeamMemberContext);
  const canSubmit = !submitData.submitting || submitData._id || isValidEmail(submitData.email);
  const ignoredIds = portfolioData.data.assurers[role].reduce((accumulator, user) => {
    if (user._id) {
      accumulator.push(user._id);
    }
    return accumulator;
  }, [] as string[]);

  const setUserPermission = (value: string, suggestion?: Suggestion) =>
    dispatch({
      type: ACTION.SET_SUBMIT_DATA,
      payload: {
        _id: suggestion ? suggestion._id : undefined,
        email: suggestion ? suggestion.name : value,
        role,
      },
    });

  const submitPermission = (role: string) => {
    if (submitData._id) {
      updatePermissions({
        userId: submitData._id,
        action: Action.Add,
        role,
      } as UpdatePermissionData);
      toggleModal();
      return;
    }
    if (submitData.email) {
      updatePermissions({
        email: submitData.email,
        action: Action.Add,
        role,
      } as UpdatePermissionData);
      toggleModal();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' className='user-search-modal'>
      <ModalHeader toggle={toggleModal}>{`Add ${role}`}</ModalHeader>
      <ModalBody>
        <BasicAlert type={'danger'} hide={!submitData.error}>{submitData.errorMessage}</BasicAlert>
        <p>Please type in a known user or e-mail address.</p>
        <FormGroup>
          <div className='row'>
            <div className='offset-2 col-8 pr-0'>
              <UserSearch
                name={'stakeholder'}
                ignoredIds={ignoredIds}
                placeholder={'Name or E-mail'}
                required={true}
                organizationId={portfolioData.data.organizationId}
                inputClassName={'form-control'}
                handleValueChange={setUserPermission}
              />
            </div>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!canSubmit}
          className='px-5'
          color='primary'
          onClick={() => submitPermission(role)}>Add {role}</Button>
      </ModalFooter>
    </Modal>
  );
};
