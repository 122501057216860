import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { UTRV_CONFIG_CODES, UtrvConfigCode, UtrvConfigType, UtrvConfigValue } from './contants';

const defaultUtrvConfig: UtrvConfigType = {
  verificationRequired: UtrvConfigValue.Default,
  evidenceRequired: UtrvConfigValue.Default,
  noteRequired: UtrvConfigValue.Default,
};

export const getInitialUtrvConfig = ({
  initiativeUtrMap,
  selectedQuestions,
}: {
  initiativeUtrMap: Map<string, InitiativeUniversalTracker>;
  selectedQuestions: BulkActionUtr[];
}) => {
  if (initiativeUtrMap.size === 0) {
    return defaultUtrvConfig;
  }

  return selectedQuestions.reduce(
    (acc, question) => {
      const initiativeUtr = initiativeUtrMap.get(question._id);
      if (!initiativeUtr) {
        return acc;
      }

      UTRV_CONFIG_CODES.forEach((code) => {
        if (acc[code] !== UtrvConfigValue.Default) {
          return;
        }
        if (initiativeUtr.utrvConfig?.[code]) {
          acc[code] = initiativeUtr.utrvConfig[code];
        }
      });
      return acc;
    },
    { ...defaultUtrvConfig }
  );
};

export const getLabelByCode = (code: UtrvConfigCode) => {
  switch (code) {
    case 'verificationRequired':
      return 'Verification';
    case 'evidenceRequired':
      return 'Evidence';
    case 'noteRequired':
      return 'Further explanation/notes';
    default:
      return '';
  }
};

export const getOptionsByCode = (code: UtrvConfigCode) => {
  return [
    {
      label: 'Use report default settings',
      value: UtrvConfigValue.Default,
    },
    {
      label: `${getLabelByCode(code)} optional`,
      value: UtrvConfigValue.Optional,
    },
    {
      label: `${getLabelByCode(code)} required`,
      value: UtrvConfigValue.Required,
    },
  ];
};

export const hasDataChanged = (defaultValue: UtrvConfigType, value: UtrvConfigType) => {
  return Object.keys(defaultValue).some(
    (code) => defaultValue[code as UtrvConfigCode] !== value[code as UtrvConfigCode]
  );
};
