import { Organization, OrganizationAssurancePortfolio, UpdateStakeholderData } from '@g17eco/types/assurance';
import { g17ecoApi, transformResponse } from '@api/g17ecoApi';
import { SafeUser } from '@constants/users';
import { OrganizationUserRole } from '@constants/user';

enum AssuranceTags {
  Organization = 'assurance-organization',
  Users = 'assurance-users',
  Portfolio = 'assurance-portfolio',
}

export type OrganizationUser = SafeUser & { invitedDate: string };

export const assuranceApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: Object.values(AssuranceTags),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAssuranceOrganizationMembers: builder.query<OrganizationUser[], { organizationId: string }>({
        transformResponse,
        query: ({ organizationId }) => ({
          url: `/assurances/organization/${organizationId}/users`,
          method: 'get',
        }),
        providesTags: (_result, _error, { organizationId }) => {
          return [{ type: AssuranceTags.Users, id: organizationId }];
        },
      }),
      // May become deprecated if we support multiple assurance organizations per user
      // currently the relationship is one to one based on the organizationId field of users
      getUserOrganization: builder.query<Organization, void>({
        transformResponse,
        query: () => ({
          url: '/users/current/organization',
          method: 'get',
        }),
        providesTags: (result, _error) => {
          return [{ type: AssuranceTags.Organization, id: result?._id }];
        },
      }),
      createOrganizationUserOnboardings: builder.mutation<
        Organization,
        { organizationId: string; emails: string[]; permissions: OrganizationUserRole[] }
      >({
        transformResponse,
        query: ({ organizationId, emails, permissions }) => ({
          url: `/organizations/${organizationId}/users/onboard`,
          method: 'post',
          data: { emails, permissions },
        }),
        invalidatesTags: (_result, _error, { organizationId }) => [
          { type: AssuranceTags.Organization, id: organizationId },
          { type: AssuranceTags.Users, id: organizationId },
        ],
      }),
      updateUserOrganizationPermissions: builder.mutation<
        OrganizationUser[],
        { organizationId: string; userId: string; permissions: OrganizationUserRole[] }
      >({
        transformResponse,
        query: ({ organizationId, userId, permissions }) => ({
          url: `/organizations/${organizationId}/users/${userId}/permissions`,
          method: 'patch',
          data: { permissions },
        }),
        invalidatesTags: (_result, _error, { organizationId }) => [
          { type: AssuranceTags.Organization, id: organizationId },
          { type: AssuranceTags.Users, id: organizationId },
        ],
      }),
      getAssurancePortfolio: builder.query<OrganizationAssurancePortfolio, string>({
        transformResponse,
        query: (id) => ({
          url: `/assurers/portfolios/${id}`,
          method: 'get',
        }),
        providesTags: (result, _error, id) => {
          return [{ type: AssuranceTags.Portfolio, id }];
        },
      }),
      updateAssurancePermissions: builder.mutation<void, { id: string; data: UpdateStakeholderData }>({
        transformResponse,
        query: ({ id, data }) => ({
          url: `/assurers/portfolios/${id}/permissions`,
          method: 'put',
          data,
        }),
        invalidatesTags: (_result, _error, { id }) => [{ type: AssuranceTags.Portfolio, id }],
      }),
    }),
  });

export const {
  useGetAssuranceOrganizationMembersQuery,
  useGetUserOrganizationQuery,
  useUpdateUserOrganizationPermissionsMutation,
  useCreateOrganizationUserOnboardingsMutation,
  useGetAssurancePortfolioQuery,
  useUpdateAssurancePermissionsMutation,
} = assuranceApi;
