import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { UtrvStatus } from '../../../constants/status';
import { useAppDispatch } from '../../../reducers';
import G17Client from '../../../services/G17Client';
import { addSiteAlert } from '../../../slice/siteAlertsSlice';
import {
  AssurancePortfolioStatus,
  AssuranceStatus,
  OrganizationAssurancePortfolio,
} from '../../../types/assurance';
import { BulkActionToolbarSelectedUtrv } from '../../survey-question-list/partials/BulkActionToolbar';
import { QUESTION } from '@constants/terminology';

interface AssureButtonProps {
  assurancePortfolio: OrganizationAssurancePortfolio;
  lookupMap: Map<string, string>;
  handleReload: () => void;
  selectedQuestions: BulkActionToolbarSelectedUtrv[];
  canAssure?: boolean;
}

export const AssureButton = ({
  assurancePortfolio,
  lookupMap,
  handleReload,
  selectedQuestions,
  canAssure = false,
}: AssureButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useAppDispatch();

    const isCompleted = assurancePortfolio?.status === AssurancePortfolioStatus.Completed;
    const questionIds = selectedQuestions.reduce((accumulator, question) => {
      const assuranceUtrvId = lookupMap.get(question._id);
      const assuranceUtrv = assurancePortfolio.universalTrackerValueAssurances.find(
        (assuranceUtrv) => assuranceUtrv._id === assuranceUtrvId
      );

      if (
        assuranceUtrv &&
        question.status === UtrvStatus.Verified &&
        assuranceUtrv.status !== AssuranceStatus.Completed
      ) {
        accumulator.push(assuranceUtrv._id);
      }
      return accumulator;
    }, [] as string[]);

    const handleAssureQuestion = async () => {
      setIsSubmitting(true);
      await G17Client.assureQuestions(assurancePortfolio._id, { questions: questionIds })
        .then(() => {
          setIsOpen(false);
          setIsSubmitting(false);
          handleReload();
        })
        .catch((e) => {
          setIsOpen(false);
          setIsSubmitting(false);
          dispatch(
            addSiteAlert({
              content: e.message,
              timeout: 5000,
            })
          );
        });
    };

    return (
      <>
        <Button
          className='ml-2 px-3 my-1'
          outline
          disabled={questionIds.length === 0 || isCompleted || !canAssure}
          onClick={() => setIsOpen(true)}
        >
          Assure selected {QUESTION.PLURAL}
        </Button>
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} backdrop='static' className='modal-md'>
          <ModalHeader toggle={() => setIsOpen(false)}>Assure individual {QUESTION.SINGULAR}(s)</ModalHeader>
          <ModalBody>
            <p>Would you like to assure the selected {questionIds.length} unassured {QUESTION.SINGULAR}(s)?</p>
          </ModalBody>
          <ModalFooter>
            <Button disabled={isSubmitting} onClick={handleAssureQuestion}>
              Assure {QUESTION.SINGULAR}(s)
            </Button>
            <Button disabled={isSubmitting} color='primary' outline onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };
