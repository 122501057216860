import { UtrvConfigType } from '@features/question-configuration/metric-override/contants';
import { InitiativeUniversalTracker } from '../types/initiativeUniversalTracker';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export interface InputOverrideParams {
  initiativeId: string;
  decimal?: Record<string, number | null>;
  unitConfig?: Record<string, Record<string, string>>;
  utrIds: string[];
}

export interface UtrvConfigOverrideParams {
  initiativeId: string;
  utrvConfig: Partial<UtrvConfigType>;
  utrIds: string[];
}

const TAG = 'initiative-universal-trackers';

const getUrl = ({ initiativeId }: { initiativeId: string }) =>
  `/initiative-universal-trackers/initiative/${initiativeId}`;

export const initiativeUniversalTrackersApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitiativeUniversalTrackers: builder.query<InitiativeUniversalTracker[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl({ initiativeId }),
          method: 'get',
        }),
        providesTags: (_result, _error, initiativeId) => [{ type: TAG, id: initiativeId }],
      }),
      updateInitiativeUniversalTracker: builder.mutation<InitiativeUniversalTracker, InputOverrideParams>({
        transformResponse,
        query: ({ initiativeId, ...data }) => ({
          url: getUrl({ initiativeId }),
          method: 'post',
          data,
        }),
        invalidatesTags: (_result, _error, arg) => [{ type: TAG, id: arg.initiativeId }],
      }),
      overrideUtrvConfig: builder.mutation<InitiativeUniversalTracker[], UtrvConfigOverrideParams>({
        transformResponse,
        query: ({ initiativeId, ...data }) => ({
          url: `${getUrl({ initiativeId })}/override/utrv-config`,
          method: 'post',
          data,
        }),
        invalidatesTags: (_result, _error, arg) => [{ type: TAG, id: arg.initiativeId }],
      }),
    }),
  });

export const {
  endpoints,
  useGetInitiativeUniversalTrackersQuery,
  useUpdateInitiativeUniversalTrackerMutation,
  useOverrideUtrvConfigMutation,
} = initiativeUniversalTrackersApi;
