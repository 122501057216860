import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ToggleButtonProps, ToggledButton } from './ToggledButton';
import SimpleTooltip from '../../simple-tooltip';

interface ActionsMenuProps extends Pick<ToggleButtonProps, 'selectedQuestions' | 'handleBulkFlagChange' | 'rootInitiativeUtrMap'> {
  openMenu: boolean;
  toggleMenu: () => void;
  canAccessVerification: boolean;
}

export const ActionsMenu = (props: ActionsMenuProps) => {
  const EvidenceButton = () =>
    ToggledButton({
      ...props,
      component: DropdownItem,
      field: 'evidenceRequired',
      action: 'Evidence optional',
      reverseAction: 'Evidence required',
      icon: 'fa-fw fa-light fa-file',
      className: ' '
    });

  const VerificationButton = () =>
    ToggledButton({
      ...props,
      component: DropdownItem,
      field: 'verificationRequired',
      action: 'Verification optional',
      reverseAction: 'Verification required',
      icon: 'fa-fw fa-light fa-user-pen',
      className: ' '
    });

  const ExplanationButton = () =>
    ToggledButton({
      ...props,
      component: DropdownItem,
      field: 'noteRequired',
      action: 'Explanation optional',
      reverseAction: 'Explanation required',
      icon: 'fa-fw fa-light fa-note',
      className: ' '
    });

  return (
    <Dropdown toggle={props.toggleMenu} isOpen={props.openMenu} className='d-inline-block more-actions'>
      <SimpleTooltip tooltip={'Evidence, verification, explanation options'}>
        <DropdownToggle color='secondary' className='px-2'>
          <i className='fal fa-bars' />
        </DropdownToggle>
      </SimpleTooltip>
      <DropdownMenu container='body'>
        <EvidenceButton />
        {props.canAccessVerification ? <VerificationButton /> : null}
        <ExplanationButton />
      </DropdownMenu>
    </Dropdown>
  );
};
