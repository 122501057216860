import { Button } from 'reactstrap';
import { CardGridGroup } from './CardGrid';
import { CardGroupName } from './utils';
import { canAccessCustomScopeGroup, hasRequiredTags } from '../../constants/groups';
import { SurveyActionData } from '../../model/surveyData';
import { Action } from '../../constants/action';
import { PACK } from '@constants/terminology';

interface CardGridFooterProps {
  cardGroups: CardGridGroup[];
  surveyData: Pick<SurveyActionData, '_id' | 'scope' | 'scopeConfig' | 'initiativeId' | 'completedDate'>;
  isDisabled: boolean;
  showMoreLess: boolean;
  showAllCards: boolean;
  toggleAllCards: () => void;
  updateScope: (data: string[], method: Action) => void;
}

export const CardGridFooter = (props: CardGridFooterProps) => {
  const {
    isDisabled,
    showMoreLess,
    toggleAllCards,
    cardGroups,
    showAllCards,
    surveyData,
    updateScope,
  } = props;

  const isMandatory = (code: string) => {
    const config = surveyData.scopeConfig?.find((config) => config.code === code);
    return config?.required === true;
  }

  const freeCards = cardGroups.find((cards) => cards.name === CardGroupName.Free)?.cards ?? [];
  const { shownInScopeCards, shownNotInScopeCards } = freeCards.slice(0, 9).reduce(
    (acc, card) => {
      if (card.inScope || card.isPartial) {
        acc.shownInScopeCards.push(card.scopeTag);
      } else {
        acc.shownNotInScopeCards.push(card.scopeTag);
      }
      return acc;
    },
    { shownInScopeCards: [] as string[], shownNotInScopeCards: [] as string[] }
  );


  const counts = cardGroups.reduce((groupAcc, cardGroup) => {
    const cardAcc = cardGroup.cards
      .filter(card => card?.unitCount)
      .reduce((cardAcc, card) => {
        if (isMandatory(card.scopeTag)) {
          return cardAcc;
        }
        const canAdd = !card.inScope && !card.isPartial;
        const canRemove = card.inScope || card.isPartial;
        return {
          canAdd: cardAcc.canAdd + (canAdd ? 1 : 0),
          canRemove: cardAcc.canRemove + (canRemove ? 1 : 0)
        }
      }, { canAdd: 0, canRemove: 0 });
    return {
      canAdd: groupAcc.canAdd + cardAcc.canAdd,
      canRemove: groupAcc.canRemove + cardAcc.canRemove
    }
  }, { canAdd: 0, canRemove: 0 });

  const handleAddAllClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const cardGroupTags: string[] = [];
    cardGroups
      .find((cards) => cards.name === CardGroupName.Free)
      ?.cards.filter((card) => {
        if (hasRequiredTags(card.requiredTags)) {
          return canAccessCustomScopeGroup(card.requiredTags, surveyData.scopeConfig);
        }
        return !showMoreLess || showAllCards || shownNotInScopeCards.includes(card.scopeTag);
      })
      .forEach((card) => cardGroupTags.push(card.scopeTag));

    updateScope(cardGroupTags, Action.Add);
  };

  const handleRemoveAllClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const cardGroupTags: string[] = [];
    cardGroups
      .find((cards) => cards.name === CardGroupName.Free)
      ?.cards.filter((card) => {
        if (card.isMandatory) {
          return false;
        }
        return !showMoreLess || showAllCards || shownInScopeCards.includes(card.scopeTag);
      })
      .forEach((card) => cardGroupTags.push(card.scopeTag));
    updateScope(cardGroupTags, Action.Remove);
  };

  return (
    <div className='py-3 text-right scope-buttons'>
      {counts.canRemove > 0 ? (
        <Button disabled={isDisabled} size='sm' outline onClick={handleRemoveAllClick}>
          <i className='fa fa-times mr-2' />
          Remove all {PACK.PLURAL} from scope
        </Button>
      ) : (
        <></>
      )}
      {counts.canAdd > 0 ? (
        <Button disabled={isDisabled} size='sm' className='ml-2' onClick={handleAddAllClick}>
          <i className='fa fa-plus mr-2' />
          Add all {PACK.PLURAL} to scope
        </Button>
      ) : (
        <></>
      )}
      {showMoreLess ? (
        <Button size='sm' className='ml-2' onClick={toggleAllCards}>
          <i className={`fas fa-chevron-${showAllCards ? 'up' : 'down'} mr-2`} />
          {`${showAllCards ? 'Show less' : 'Show more'}`}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};
