import { useState } from 'react';
import { Button, Badge } from 'reactstrap';
import { roles } from '../../constants/roles';
import { SurveyUserRoles } from '../../constants/users';
import { MassDelegation } from '../../types/survey';
import { RadioInput } from '../form/RadioInput';
import { Suggestion } from '../search/SearchComponent';
import UserSearch from '../search/UserSearch';
import { DelegatedUsers } from './types';
import { isAssignedToAll } from './ultis';

interface Props {
  stopDelegating: () => void;
  toggle: () => void;
  delegateCallback: ({ role, userId }: Pick<MassDelegation, 'role' | 'userId'>) => void;
  surveyId: string;
  role: SurveyUserRoles;
  delegatedUsers: DelegatedUsers;
  utrvsLength: number;
  canAccessVerification: boolean;
}

const bothRolesCode = 'both';

const getRoleOptions = (canAccessVerification: boolean) => {
  const disabled = !canAccessVerification;
  const tooltip = disabled ? 'Upgrade to access verification' : '';
  return [
    { code: SurveyUserRoles.Stakeholder, name: roles[SurveyUserRoles.Stakeholder].shortName },
    { code: SurveyUserRoles.Verifier, name: roles[SurveyUserRoles.Verifier].shortName, disabled, tooltip },
    {
      code: bothRolesCode,
      name: `${roles[SurveyUserRoles.Stakeholder].shortName} and ${roles[SurveyUserRoles.Verifier].shortName}`,
      disabled,
      tooltip,
    },
  ];
};

export const Delegation = ({
  stopDelegating,
  toggle,
  delegateCallback,
  surveyId,
  role,
  delegatedUsers,
  utrvsLength,
  canAccessVerification,
}: Props) => {
  const [users, setUsers] = useState<Suggestion[]>([]);
  const [selectingRole, setSelectingRole] = useState<SurveyUserRoles | typeof bothRolesCode>(role);

  const roles =
    bothRolesCode === selectingRole ? [SurveyUserRoles.Stakeholder, SurveyUserRoles.Verifier] : [selectingRole];

  const ignoredIds = [...delegatedUsers.contributors, ...delegatedUsers.verifiers]
    .filter((u) => !!u._id && roles.every((role) => isAssignedToAll(role, u, utrvsLength)))
    .map((u) => u._id);

  const handleUserSelected = (user?: Suggestion) => {
    if (user) {
      setUsers([...users, user]);
    }
  };

  const onClose = () => {
    setUsers([]);
    stopDelegating();
  };

  const onChangeRole = ({ value }: { value: SurveyUserRoles }) => setSelectingRole(value);

  const onDelegate = () => {
    delegateCallback({ role: roles, userId: users.map(({ _id }) => _id).filter((id) => !ignoredIds.includes(id)) });
    onClose();
  };

  return (
    <>
      <Button color='link' onClick={onClose}>
        <i className='fa fa-chevron-left mr-2' />
        Back
      </Button>
      <p className='mt-3'>
        Search for the users you wish to delegate to. If you are unable to find the user below, please first ensure you
        have invited them to the platform via the manage users page:
      </p>

      <UserSearch
        name='stakeholder'
        placeholder='Type users name here...'
        ignoredIds={[...ignoredIds, ...users.map(({ _id }) => _id)]}
        surveyId={surveyId}
        inputClassName={'form-control'}
        handleValueChange={() => {}}
        handleSuggestionSelected={handleUserSelected}
      />
      {users.map((user) => (
        <Badge color='primary' className='mr-2 mt-2 background-ThemeInfoDark text-md p-2 dont_translate' tag='div' key={user._id}>
          {user.name} ({user.email})
        </Badge>
      ))}
      <h6 className='text-ThemeTextDark'>Delegate as:</h6>
      <RadioInput
        groupCode='delegation-role'
        value={selectingRole}
        options={getRoleOptions(canAccessVerification)}
        onChange={onChangeRole}
        className='d-flex'
        formGroupClassName='mr-5'
      />
      <div className='d-flex justify-content-end mt-5'>
        <Button color='link-secondary' onClick={toggle} className=''>
          Close
        </Button>
        <Button color='primary' onClick={onDelegate} disabled={!users.length}>
          Delegate
        </Button>
      </div>
    </>
  );
};
