export enum UtrvConfigValue {
  Default = 'default',
  Optional = 'optional',
  Required = 'required',
}

export const UTRV_CONFIG_CODES = ['verificationRequired', 'evidenceRequired', 'noteRequired'] as const;

export type UtrvConfigCode = (typeof UTRV_CONFIG_CODES)[number];

export type UtrvConfigType = Record<UtrvConfigCode, UtrvConfigValue>;
